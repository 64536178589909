import React, { useEffect } from 'react'
import AboutSection from "../components/AboutSection"
import { useDispatch, useSelector } from 'react-redux'
import { fetchData } from '../redux/reducers/homeSlice'
import { Helmet } from 'react-helmet-async'
function About() {
  const dispatch = useDispatch()
  useEffect(() => {
      dispatch(fetchData())
  }, [dispatch])
  const data = useSelector((state) => state?.home?.data);
  return (
    <div>
      <Helmet>
        <title>
          About Page
        </title>
        <meta name='description' content='This is our About Page'  />
        <link rel="canonical" href="/about" />
      </Helmet>
      <AboutSection data={data?.pages?.[0]?.sections}/>
    </div>
  )
}

export default About