import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../redux/reducers/homeSlice';
import AboutSection from '../components/AboutSection';
import { Helmet } from 'react-helmet-async';

function Contact() {
  const dispatch = useDispatch()
  useEffect(() => {
      dispatch(fetchData())
  }, [dispatch])
  const data = useSelector((state) => state?.home?.data);
  return (
    <div>
       <Helmet>
        <title>
          Contact Page
        </title>
        <meta name='description' content='This is our Contact Page'  />
        <link rel="canonical" href="/contact" />
      </Helmet>
      <AboutSection data={data?.pages?.[1]?.sections}/>
    </div>
  )
}

export default Contact